<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <main-header />

  <!-- Breadcrumb -->
  <div class="container">
    <div class="_flex _center">
      <nav aria-label="breadcrumb" style="height:40px!important">
        <ol class="breadcrumb  bg-transparent tiny">
          <li class="breadcrumb-item"><a href="#">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">
           Mes achats
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <section class="container">
    <br />
    <div class="contained">
      <div class="text-center">
        <h2><b>Mes achats</b></h2>
      </div>
      <br /><br />
      <!--  -->
      <ul class="list-unstyled">
        <li class="cart-item row" v-for="x in 5" :key="x">
          <div class="col-12 col-md-6 col-lg-7">
            <div class="cart-item-title">
              <img
                src="https://d5wt70d4gnm1t.cloudfront.net/media/a-s/artworks/zhang-wei-guang-mirror/64240-584659269798/zhang-wei-guang-mirror-fresh-newseggs-and-ceramics-512x384-c.jpg"
                alt=""
              />
              <span>Inbox - Subtle Actions T-Shirt</span>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <div class="form-inline">
                  <label class="is-13">Qty:</label>
                  <select class="form-control border-0">
                    <option :value="n + 1" v-for="n in 20" :key="n + 1">{{
                      n + 1
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="col-3 text-center">
                <b>$17</b>
              </div>
              <div class="col-3 text-right">
                <a
                  class="c-pointer"
                  title="Supprimer cet article dans le panier"
                >
                  <i class="fal fa-times "></i>
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="row no-gutters">
        <div class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 text-md-right pr-md-5">
              Total: <span>&nbsp;&nbsp;&nbsp;</span> $154.75
        </div>
      </div>
    </div>
  </section>
  <br /><br /><br />

  <main-footer />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true
  })
};
</script>

<style></style>
